export default {
    title: 'Zero Waste',
    location: '/sustainability/zero-waste',
    tabs: [
        {
            name: 'By Country',
            link: `/sustainability/zero-waste`
        },
        {
            name: 'Trend',
            link: `/sustainability/zero-waste/:market`
        }
    ]
}
